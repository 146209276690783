<template>
  <div class="h-100" style="min-height:100vh;">
    <b-row class="justify-content-center h-100 mx-0" style="min-height:100vh;">
      <b-col class="px-0">
        <!-- <fullscreen @change="fullscreenChange" ref="fullscreen" style="height:100vh;"> -->
          <div id="fullscreen-div">
          <!-- <b-aspect aspect="16:9"> -->
            <div id="showmodals" />
          <iframe
            ref="iframe"
            :src="urls"
            width="100%"
            height="100%"
            class="display-frame"
            frameborder="0"
          >
          </iframe>
          <div class="fixed-bottom text-left mx-3 my-2 fs-depth">
            <b-button @click="toggle" class="custom-btn" v-if="!fullscreen && !android">
              <b-icon
                icon="fullscreen"
                size="3rem"
                style="color: #004edf;"
              ></b-icon>
            </b-button>
          </div>
          <!-- <Popup :ids="idToken" :dataPopup="dataToken" @addCoin ="updatePoin" /> -->
          <PopupReward :ids="idPopup" :dataPopup="datapopup"/>
        </div>
        <!-- </fullscreen> -->
      </b-col>
    </b-row>
  </div>
</template>
<script>
// import fullscreen from "vue-fullscreen";
// import Vue from "vue";
// import { apiPortal, response } from '@/provider'
import PopupReward from '@/components/Popup.vue'
import { mapActions, mapState, mapMutations } from 'vuex';
// Vue.use(fullscreen);
export default {
  components: {
    // Popup,
    PopupReward,
  },
  //  mixins: [ PopupReward ],
  data() {
    return {
      statusRequest:0,
      fullscreen: false,
      urls:'#',
      iframeGame: {},
      storeData: {
        game_id: this.gameId,
        msisdn: this.$store.state.user,
        token_id: this.$store.state.tokenLife,
        data: [],
      },
      loadAjax: false,
      idPopup:null,
      datapopup: {
        image: null,
        title: null,
        description: null,
        txtButton: null,
        show: false
      },
      dataToken: {
        image: null,
        title: null,
        description: null,
        txtButton: null,
      },
      options:{title_value:'score',mode_game:'round'},
      // showPopupReward = false
      // profile: null,
    };
  },
  computed: {
    ...mapState([
      'gameId',
      'user',
      'tokenLife',
      'popupReward',
      'popupToken',
      'url',
      'reward',
      'coin',
      'profile',
      'mode',
      'android',
      'leaderboardList',
    ])
  },
  beforeMount() {
    this.$store.state.isLoading = true
    this.getGames().then(async() => {
      this.urls= this.setUrl(this.url)
      // this.urls= "http://localhost/portalgame/galaxywar";
      this.getGameReward()
    }, error => {
      this.statusRequest=0;
      console.error('err', error);
    })
    
  },
  mounted() {
    this.setPopupShow(false)
    const deviceAndroid = this.android
    window.addEventListener("message", event => {
      const data = event.data;
      switch (data.cmd) {
        case "popupHadiah":
        //  this.showPopupReward(data.param.data.point);
          break;
        case "popupToken":
          this.showPopupValidateToken();
          break;
        case "getPlayerAppData":
            this.getPlayerAppData().then(() => {
                const playerData = this.$store.state.playerData;
                let params={
                  success: true,
                  data:{ball:playerData.ball,costume:playerData.costume},
                }
                this.accessMethodeGame('startPlayerAppData', params);
              }, error => {
                this.statusRequest=0;
                console.error('err', error);
              })
        break;
        case "getProfile":
          if(this.statusRequest==0){
            this.statusRequest=1;
            this.getDetail().then(async() => {
               const dataPoint = this.$store.state.point
               const dataLife = this.$store.state.coin
               const dataTelco = this.$store.state.telco
               const dataMSISDN = this.$store.state.user
              if(dataTelco=='tsel' || dataTelco=='smart')this.setReward(null)
              this.statusRequest=0;
              const params = {
                  success: true,
                  data: JSON.stringify({ point: dataPoint, life: dataLife, msisdn: dataMSISDN, dificult: 1, avatar: null, telco: {mno_shortname: dataTelco} }),
                  reward: this.reward,
                  type: 1,
                  options:{title_value:'poin',mode_game:'round'},
                }
                this.$store.state.isLoading = false
                this.accessMethodeGame('setProfile', params);
            }, error => {
              this.statusRequest=0;
              console.error('err', error);
            })
          }
          break;
        case "getTokenLife":
          if(this.statusRequest==0){
            this.statusRequest=1
            this.getLife().then(() => {
              const params = {
                success: true,
                data: {},
              }
              if (this.tokenLife === null || this.coin === 0) {
                params.success=false;
                this.accessMethodeGame('setTokenLife', params);
              } else {
                this.accessMethodeGame('setTokenLife', params); 
              }
              this.statusRequest=0
            }, error => {
              this.statusRequest=0
              console.error('err', error);
            })
          }
          break;
        case "storePoint": {
          let dataPoint = data.param.data.point
          let totalPoint = dataPoint[0].point.toString()
          if (this.mode !== 'free') {
            // this.store(data.param.data.point)
            this.storePoint(dataPoint).then(() => {
              const params = {
                success: true,
                data: {},
              }
             // this.showPopupReward(totalPoint)
              this.accessMethodeGame('statusStorePoint', params);
            }, error => {
              console.error('err', error);
              const params = {
                success: false,
                data: {},
              }
              this.accessMethodeGame('statusStorePoint', params);
            })
          } else {
            this.$store.commit('SET_POINT', parseInt(totalPoint))
            const params = {
              success: true,
              data: {},
            }
            // console.log(dataPoint, totalPoint);
          //  this.showPopupReward(totalPoint)
            this.accessMethodeGame('statusStorePoint', params);
          }
          /*
          var data = [
            {
              id: 0,
              name: 'Coin',
              point: '10',
            },
          ]
          */
          break;
        }
        case "getInfo": {
          this.datapopup.txtButton = "Oke";
          this.idPopup='popupInfo'
          this.setPopupShow(true)
          break;
        }
          case "updatePlayerAppData": {
            this.setPlayerData(data.param.data.point).then(() => {
              const params = {
                success: true,
                data: {},
              }
              this.accessMethodeGame('setPlayerAppData', params);
            }, error => {
              console.error('err', error);
              const params = {
                success: false,
                data: {},
              }
              this.accessMethodeGame('setPlayerAppData', params);
            })
            // const data = data.param.data.point
          break;
          }
        case "getService": {
          this.getService().then(() => {
                this.idPopup='popupService'
                this.setPopupShow(true)
              }, error => {
                this.statusRequest=0;
                console.error('err', error);
              })
          break;
        }
        case "getLeaderboard": {
             this.getLeaderboard().then(() => {
                let params={
                  success: true,
                  data: this.leaderboardList,
                }
                this.accessMethodeGame('setLeaderboard', params);
              }, error => {
                this.statusRequest=0;
                console.error('err', error);
              })
              break;
        }
        case "goFullscreen": {
          if(!this.fullscreen && !deviceAndroid){
            this.toggle();
          }
          break;
        }
      }
    });
    this.iframeGame = this.$refs.iframe.contentWindow;
    document.addEventListener("fullscreenchange", this.fullscreenchanged);
  },
  methods: {
    ...mapActions([
      'getProfile',
      'getDetail',
      'getLife',
      'storePoint',
      'setPlayerData',
      'getGameReward',
      'getGames',
      'getPlayerAppData',
      'getService',
      'getLeaderboard',
    ]),
    ...mapMutations({
      setReward: 'SET_REWARD',
      setPopupShow: 'SET_GAME_POPUP_SHOW',
    }),
    toggle() {
      // if(this.$refs["fullscreen"]!=undefined)this.$refs["fullscreen"].toggle(); // recommended
      if (document.fullscreenElement) {
        // exitFullscreen is only available on the Document object.
        document.exitFullscreen();
      } else {
        const fullScreenElement = document.querySelector("#fullscreen-div");
        fullScreenElement.requestFullscreen();
      }
    },
    fullscreenchanged(){
      if (document.fullscreenElement) {
        this.fullscreen=true;
      } else {
        this.fullscreen=false;
      }
    },
    setUrl(url) {
      const sUrl = url.replace(/^http:\/\//i, 'https://');
      return `${sUrl}?msisdn=${this.$store.state.user}`
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
      // console.log(fullscreen)
    },
     showPopupValidateToken() {
      this.idPopup='popupToken'
      this.setPopupShow(true)
      this.setPopupToken()
    },
    showPopupReward(point) {
      this.setPopupReward(point)
    },
    setPopupToken() {
      const token = this.popupToken;
      this.datapopup.image = token.image;
      this.datapopup.title = token.title;
      this.datapopup.description = token.description;
      this.datapopup.txtButton = "Tambah Token";

      // this.showPopup(this.idToken)
    },
    setPopupReward(point) {
        const index = this.popupReward.findIndex(
          item => item.point === point
        );
        if (index !== -1) {
           this.idPopup='popupReward'
          this.setPopupShow(true)
          const rewards = this.popupReward[index];
          this.datapopup.image = rewards.image;
          this.datapopup.title = rewards.title;
          this.datapopup.description = rewards.description;
          this.datapopup.txtButton = "Ambil";
          
          // this.showPopup(this.idReward)
        }
    },
    // store(point) {
    //   // const sendData = {
    //   //       game_id: this.gameId,
    //   //       msisdn: this.user,
    //   //       token_id: this.tokenLife,
    //   //       data: point,
    //   //     }
    //   // console.log(sendData);
    //   this.storePoint(point).then(() => {
    //     const params = {
    //       success: true,
    //       data: {},
    //     }
    //     this.accessMethodeGame('statusStorePoint', params);
    //   })
    // },
    accessMethodeGame(command, params) {
      this.iframeGame.postMessage(
        {
          cmd: command,
          param: params,
        },
        "*"
      );
    },
    updatePoin() {
      // this.totalCoin = 1
      console.log('update coin');
    }
  }
};
</script>
<style>
</style>
<style scoped>
.modal-footer button {
  display: block;
  width: 100%;
  color: #f8f9fa !important;
  border-radius: 20px;
  background: linear-gradient(90deg, #ff4401 0%, #ff0077 57.29%);
}
.display-frame {
  height:calc(100vh - 0px);
  width:calc(100vw - 0px);
  box-sizing: border-box;
  position: fixed;
  left: 0px;
  top: 0px;
}
.fs-depth{
  width: 100px;
}
.custom-btn, .custom-btn:hover,.custom-btn:active{
  padding: 0px;
  border: none !important;
  background-color:#ffffff69;
  padding-top:5px;
  height: 50px;
  border-radius: 25px;
  width: 50px;
}
@media (orientation: landscape) {
   .modal-dialog {
    width: 50vh !important;
    margin: 0 auto !important;
  }
  .modal-body {
    padding-bottom: 0px;
  }
  .txt-desc {
    font-size: 0.8rem;
  }
}

@media (orientation: portrait) {
  .modal-dialog {
    width: 50vw !important;
    margin: 0 auto !important;
  }
  .txt-desc {
    font-size: 0.8rem;
  }
}
/* .modal-dialog {
  width: 75% !important;
  margin: 0 auto !important;
} */
</style>
